import React from 'react';
import { FormPage } from './FormPage';
import './Form.scss';
import { useFormContext } from '../../hooks/useFormContext';
import { Confirmation, FormError } from '../../models/form.models';
import { FormSummary } from './FormSummary';

export const Form: React.FC<{
  submitButtonDisabled: boolean;
  goToPage: (pageIndex: number, steps: number) => void;
  validatePages: (pageIndex: number) => void;
  fieldChange: (event: any, pageIndex: number, field: any) => void;
  confirmationChange: (confirmations: Array<Confirmation>) => void;
  formSubmit: () => Promise<void>;
  fileUpload: (file: any, pageIndex: number, field: any) => Promise<any>;
  fileDelete: (id: string, pageIndex: number, field: any) => Promise<any>;
  fileClick: (file: any, pageIndex: number, field: any) => Promise<any>;
  dismissNotification: (e: FormError) => void;
}> = (props) => {
  const { formState } = useFormContext();

  const form = formState.form;

  const formCurrentPage = form.pages.find((p: any) => p.pageIndex === formState.currentPage);

  return (
    <React.Fragment>
      {form?.submitted ? (
        <FormSummary goToField={() => {}} onFileClick={() => {}} onValidationClick={() => {}} />
      ) : (
        <div className="form">
          <FormPage
            onFieldChange={(event: any, field: any) => props.fieldChange(event, formCurrentPage.pageIndex, field)}
            onFileUpload={(file: any, field: any) => props.fileUpload(file, formCurrentPage.pageIndex, field)}
            onFileDelete={(id: string, field: any) => props.fileDelete(id, formCurrentPage.pageIndex, field)}
            onFormSubmit={props.formSubmit}
            onConfirmationChange={props.confirmationChange}
            onFileClick={props.fileClick}
            goToPage={(steps: number) => props.goToPage(formCurrentPage.pageIndex, steps)}
            validatePages={props.validatePages}
            page={formCurrentPage}
            submitButtonDisabled={props.submitButtonDisabled}
            dismissNotification={props.dismissNotification}
          />
        </div>
      )}
    </React.Fragment>
  );
};
