import React from 'react';
import { FormActionTypes } from '../../context/form-reducer';
import { useFormContext } from '../../hooks/useFormContext';
import { PushSiteImproveEvent } from '../../utils/siteimproveEvents';
import { FormSummaryField } from './FormSummaryField';

export const FormSummary = (props: any) => {
  const { formState, dispatch } = useFormContext();

  const onValidationClick = (pageIndex: number, fieldName: string) => {
    dispatch({ type: FormActionTypes.SetCurrentPage, payload: pageIndex - formState.currentPage });
    dispatch({ type: FormActionTypes.FocusField, payload: fieldName });
  };

  const onEditClicked = (pageNumber: number, fieldName: string) => {
    PushSiteImproveEvent('SummaryEditField', fieldName);
    props.goToField(pageNumber - formState.currentPage, fieldName);
  };

  const getPagesWithFields = (p: any) => {
    return [...(!!p.fields ? [p] : []), ...(!!p.pages ? p.pages.flatMap(getPagesWithFields) : [])]
  }

  return (
    <div className="formSummary">
      {formState.form.pages
        .flatMap(getPagesWithFields)
        .map((page: any) => {
          return (
            <div className="formSummaryPage" key={`summary-${page.pageIndex}`}>
              <h3 className="h3AdjustedForSummaryPage">{page.title}</h3>
              {page.fields?.map((field: any, fieldNumber: number) => {
                return (
                  <FormSummaryField
                    {...props}
                    field={field}
                    pageNumber={page.pageIndex}
                    onValidationClick={() => onValidationClick(page.pageIndex, field.name)}
                    onFileClick={(file: any) => props.onFileClick(file, page.pageIndex, field)}
                    key={`summary-${page.pageIndex}-${fieldNumber}`}
                    goToField={() => onEditClicked(page.pageIndex, field.name)}
                  />
                );
              })}
            </div>
          );
        })}
    </div>
  );
};
