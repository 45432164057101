import { Button } from '@in/component-library';
import React from 'react';
import './FormIntroductionPageWrapper.scss';
import { useFormContext } from '../../../hooks/useFormContext';

export const FormIntroductionPageWrapper: React.FC<{ page: any; goToPage: (steps: number) => void }> = ({ page, goToPage }) => {
  const { formState } = useFormContext();

  return (
    <div className="form-introduction">
      {page.logo?.url && <img src={page.logo?.url} alt={page.logo?.altText} />}
      <div className="form-introduction__info">
        <h2>{page.title}</h2>
        <div dangerouslySetInnerHTML={{ __html: page.description }} />
      </div>
      <div className="form-introduction__actions">
        <Button onClick={() => goToPage(1)}>
          {formState.formId !== '0' ? formState.form.globalLabels.continueFormLabel : formState.form.globalLabels.startFormLabel}
        </Button>
      </div>
    </div>
  );
};
